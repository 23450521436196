import styled from "styled-components";
import { Link } from "gatsby";
import { colors } from "../styles/variables";

export const Button = styled(Link)`
  display: inline-block;
  margin: 1.5rem 0 0 0;
  color: #fff;
  border: solid 1px ${colors.primary};
  background-color: ${colors.primary};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  font-size: 0.8rem;
  &:hover {
    color: #fff;
    background-color: ${colors.lightBlue};
    border-color: ${colors.lightBlue};
  }
`;

export const ExternalButton = styled.a`
  display: inline-block;
  margin: 1.5rem 0 0 0;
  color: #fff;
  border: solid 1px ${colors.primary};
  background-color: ${colors.primary};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  font-size: 0.8rem;
  &:hover {
    color: #fff;
    background-color: ${colors.lightBlue};
    border-color: ${colors.lightBlue};
  }
`;
